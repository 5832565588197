<template>
  <div>
    <div v-html="title" />
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        :disabled="loading"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row
          class="match-height"
        >
          <b-col
            sm="4"
          >
            <password-input
              id="xbu_pwd"
              name="xbu_pwd"
              :value.sync="xbu_pwd"
              :get-validation-state="getValidationState"
              label="Новый пароль"
              api=""
            />                </b-col>
        </b-row>
        <div class="d-flex mt-2 justify-content-start">
          <b-button
            variant="primary"
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>Сохранить</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'

import { ref } from '@vue/composition-api'

                                      import PasswordInput from '@core/components/input/password-input/PasswordInput.vue'
                            
export default {
  components: {
    BForm,
    BRow,
    BCol,
    ValidationObserver,
    BButton,
    BSpinner,

                                                          PasswordInput,
                                          },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sendApi: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
      default: () => 'post',
    },
    getApi: {
      type: String,
      required: false,
    },
  },
  setup(props, { root }) {
                const xbu_pwd = ref('')
          
    const resetData = () => {
            xbu_pwd.value = ''
          }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    if (props.getApi) {
      axios.get(props.getApi)
        .then(response => {
          const { data } = response.data
                                  xbu_pwd.value = data['xbu_pwd']
                              })
    }

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      let formData = {
                xbu_pwd: xbu_pwd.value,
              }

      
      if (props.sendApi) {
        if (props.method === 'post') {
          axios.post(props.sendApi, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(() => {
              const { data } = err.response.data

              root.$swal({
                icon: 'error',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              loading.value = false
            })
        }

        if (props.method === 'put') {
          axios.put(props.sendApi, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                text: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(() => {
              loading.value = false
            })
        }
      }
    }

          return {
        refFormObserver,
        getValidationState,
        resetForm,
        onSubmit,
        loading,
                xbu_pwd,
              }
      },
}
</script>
