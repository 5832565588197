<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="rules"
  >
    <b-form-group
      :label="label"
      :label-for="id"
      :class="classess"
    >
      <b-form-datepicker
        :id="id"
        :value="value"
        :state="rules ? getValidationState(validationContext) : null"
        trim
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        locale="ru-RU"
        class="text-left"
        @input="val => {
          $emit('update:value', val)
        }"
      />
      <b-form-invalid-feedback :state="rules ? getValidationState(validationContext) : null">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup,
  BFormDatepicker,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required,
  alphaNum,
  email,
  equal,
} from '@validations'

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    BFormDatepicker,
  },
  props: {
    value: {
      type: [String, null],
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    getValidationState: {
      type: Function,
      required: false,
      default: () => true,
    },
    subtract: {
      type: String,
      required: false,
      default: '',
    },
    rules: {
      type: String,
      required: false,
      default: () => '',
    },
    classess: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      equal,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
