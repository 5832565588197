<template>
  <div>
    <div v-html="title" />
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        :disabled="loading"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row
          class="match-height"
        >
          <b-col
            sm="4"
          >
            <text-input
              id="xbu_LN"
              name="xbu_LN"
              :value.sync="xbu_LN"
              :get-validation-state="getValidationState"
              rules="required"
              label="Фамилия"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <text-input
              id="xbu_FN"
              name="xbu_FN"
              :value.sync="xbu_FN"
              :get-validation-state="getValidationState"
              rules="required"
              label="Имя"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <text-input
              id="xbu_MN"
              name="xbu_MN"
              :value.sync="xbu_MN"
              :get-validation-state="getValidationState"
              rules=""
              label="Отчество"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <b-form-group
              label="Дата рождения"
              label-for="xbu_DateOfBrith"
            >
              <date-picker
                v-model="xbu_DateOfBrith"
                class="w-100"
                format="DD.MM.YYYY"
                placeholder="дд.мм.гггг"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="4"
          >
            <text-input
              id="xbu_mail"
              name="xbu_mail"
              :value.sync="xbu_mail"
              :get-validation-state="getValidationState"
              rules=""
              label="E-mail"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <phone-input
              id="xbu_tel"
              name="xbu_tel"
              :value.sync="xbu_tel"
              :get-validation-state="getValidationState"
              rules=""
              label="Телефон"
              api=""
            />                </b-col>
        </b-row>
        <div class="d-flex mt-2 justify-content-start">
          <b-button
            variant="primary"
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>Сохранить</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'
import moment from 'moment'

import { ref, watch } from '@vue/composition-api'

import TextInput from '@core/components/input/text-input/TextInput.vue'
import DateInput2 from '@core/components/input/date-input/DateInput2.vue'
import PhoneInput from '@core/components/input/phone-input/PhoneInput.vue'
                            
export default {
  components: {
    BForm,
    BRow,
    BCol,
    ValidationObserver,
    BButton,
    BSpinner,
    BFormGroup,

                                                          TextInput,
                                                                                                              PhoneInput,
    DateInput2,
                                          },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sendApi: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
      default: () => 'post',
    },
    getApi: {
      type: String,
      required: false,
    },
    apiId: {
      type: [Object, String, Number, null],
      required: false,
    },
    loadById: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup(props, { root }) {
                const xbu_LN = ref('')
            const xbu_FN = ref('')
            const xbu_MN = ref('')
            const xbu_mail = ref('')
            const xbu_tel = ref('')
            const xbu_DateOfBrith = ref('2021-12-09')
          
    const resetData = () => {
            xbu_LN.value = ''
            xbu_FN.value = ''
            xbu_MN.value = ''
            xbu_mail.value = ''
            xbu_tel.value = ''
            xbu_DateOfBrith.value = ''
          }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const fetchData = () => {
      if (props.getApi) {
        const url = props.apiId ? `${props.getApi}/${props.apiId}` : props.getApi
        axios.get(url)
          .then(response => {
            const { data } = response.data
                                        xbu_LN.value = data['xbu_LN']
                            xbu_FN.value = data['xbu_FN']
                            xbu_MN.value = data['xbu_MN']
                            xbu_mail.value = data['xbu_mail']
                            xbu_tel.value = data['xbu_tel']
                            xbu_DateOfBrith.value = data['xbu_DateOfBrith']
                                    })
      }
    }

    if (!props.loadById) {
      fetchData()
    }

    watch(() => props.apiId, () => {
      fetchData()
    })

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      let formData = {
                xbu_ln: xbu_LN.value,
                xbu_fn: xbu_FN.value,
                xbu_mn: xbu_MN.value,
                xbu_mail: xbu_mail.value,
                xbu_tel: xbu_tel.value,
                xbu_dateofbrith: xbu_DateOfBrith.value && moment(xbu_DateOfBrith.value).format('MM.DD.YYYY'),
              }

      
      if (props.sendApi) {
        const url = props.apiId ? `${props.sendApi}/${props.apiId}` : props.sendApi
        if (props.method === 'post') {
          axios.post(url, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(() => {
              const { data } = err.response.data

              root.$swal({
                icon: 'error',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              loading.value = false
            })
        }

        if (props.method === 'put') {
          axios.put(url, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                text: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(() => {
              loading.value = false
            })
        }
      }
    }

          return {
        refFormObserver,
        getValidationState,
        resetForm,
        onSubmit,
        loading,
                xbu_LN,
                xbu_FN,
                xbu_MN,
                xbu_mail,
                xbu_tel,
                xbu_DateOfBrith,
              }
      },
}
</script>
