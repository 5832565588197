<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="rules"
  >
    <b-form-group
      :label="label"
      :label-for="id"
      :class="classess"
    >
      <b-input-group>
        <b-form-input
          :id="id"
          :value="value"
          :state="rules ? getValidationState(validationContext) : null"
          :type="passwordFieldType"
          placeholder="············"
          trim
          @input="val => {
            $emit('update:value', val)
          }"
        />
        <b-input-group-append is-text>
          <feather-icon
            :icon="passwordToggleIcon"
            class="cursor-pointer"
            @click="togglePasswordVisibility"
          />
        </b-input-group-append>
      </b-input-group>
      <b-form-invalid-feedback :state="rules ? getValidationState(validationContext) : null">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  required,
  alphaNum,
  email,
  equal,
} from '@validations'

export default {
  components: {
    BInputGroup,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    BFormInput,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  props: {
    value: {
      type: [String, null],
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
      default: () => '',
    },
    getValidationState: {
      type: Function,
      required: false,
      default: () => true,
    },
    classess: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      equal,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
