<template>
  <div>
    <div v-html="title" />
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        :disabled="loading"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row
          class="match-height"
        >
          <b-col
            sm="4"
          >
            <text-input
              id="postcode"
              name="postcode"
              :value.sync="postcode"
              :get-validation-state="getValidationState"
              label="Индекс"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <text-input
              id="country"
              name="country"
              :value.sync="country"
              :get-validation-state="getValidationState"
              label="Страна"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <text-input
              id="town_name"
              name="town_name"
              :value.sync="town_name"
              :get-validation-state="getValidationState"
              label="Город"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <text-input
              id="street"
              name="street"
              :value.sync="street"
              :get-validation-state="getValidationState"
              label="Адрес"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <text-input
              id="house"
              name="house"
              :value.sync="house"
              :get-validation-state="getValidationState"
              label="Дом"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <number-input
              id="flat"
              name="flat"
              :value.sync="flat"
              :get-validation-state="getValidationState"
              label="Квартира/Офис"
              api=""
            />                </b-col>
          <b-col
            sm="4"
          >
            <textarea-input
              id="info"
              name="info"
              :value.sync="info"
              :get-validation-state="getValidationState"
              label="Комментарий к адресу"
              api=""
            />                </b-col>
        </b-row>
        <div class="d-flex mt-2 justify-content-start">
          <b-button
            variant="primary"
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>Сохранить</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'

import { ref } from '@vue/composition-api'

                                      import TextInput from '@core/components/input/text-input/TextInput.vue'
                                                                                                                                                      import NumberInput from '@core/components/input/number-input/NumberInput.vue'
                                                              import TextareaInput from '@core/components/input/textarea-input/TextareaInput.vue'
                            
export default {
  components: {
    BForm,
    BRow,
    BCol,
    ValidationObserver,
    BButton,
    BSpinner,

                                                          TextInput,
                                                                                                                                                                                                              NumberInput,
                                                                              TextareaInput,
                                          },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sendApi: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
      default: () => 'post',
    },
    getApi: {
      type: String,
      required: false,
    },
  },
  setup(props, { root }) {
                const postcode = ref('')
            const street = ref('')
            const house = ref('')
            const flat = ref('')
            const town_name = ref('')
            const country = ref('')
            const info = ref('')
          
    const resetData = () => {
            postcode.value = ''
            street.value = ''
            house.value = ''
            flat.value = ''
            town_name.value = ''
            country.value = ''
            info.value = ''
          }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    if (props.getApi) {
      axios.get(props.getApi)
        .then(response => {
          const { data } = response.data
                                  postcode.value = data['address']['postcode']
                        street.value = data['address']['street']
                        house.value = data['address']['house']
                        flat.value = data['address']['flat']
                        town_name.value = data['address']['town_name']
                        country.value = data['address']['country']
                        info.value = data['address']['info']
                              })
    }

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      let formData = {
                postcode: postcode.value,
                street: street.value,
                house: house.value,
                flat: flat.value,
                town_name: town_name.value,
                country: country.value,
                info: info.value,
              }

            formData = {
        address: JSON.stringify({ ...formData }),
      }
      
      if (props.sendApi) {
        if (props.method === 'post') {
          axios.post(props.sendApi, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(() => {
              const { data } = err.response.data

              root.$swal({
                icon: 'error',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              loading.value = false
            })
        }

        if (props.method === 'put') {
          axios.put(props.sendApi, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                text: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(() => {
              loading.value = false
            })
        }
      }
    }

          return {
        refFormObserver,
        getValidationState,
        resetForm,
        onSubmit,
        loading,
                postcode,
                street,
                house,
                flat,
                town_name,
                country,
                info,
              }
      },
}
</script>
